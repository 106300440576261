@import url("https://fonts.cdnfonts.com/css/salsa");
body {
  margin: 0;
  padding: 0;
  font-family: "Salsa", sans-serif;
  background-color: #66ccff; /* Light Blue */
}

.App-header{
    background-color: #66ccff; /* Light Blue */
}

.container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #003366; /* Primary Blue */
}

h1,
p {
  opacity: 0;
}

.fade-in {
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.logo {
  display: flex;
  justify-content: center;
}
@media (max-width: 575px) {
  .logo {
    max-width: 75%;
    margin: 0 auto;
  }
}
.logo:after {
  content: "";
  background: url("../assets/images/logo.svg") center center/cover no-repeat;
  background-repeat: no-repeat;
  display: block;
  width: 400px; /* Set the width of your div */
  height: 300px; /* Set the height of your div */
}
@media (max-width: 575px) {
  .logo:after {
    height: 200px;
  }
}/*# sourceMappingURL=main.css.map */